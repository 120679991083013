/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [captcha, setCaptcha] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    if (captcha === '') {
      setEmailSuccess(false);
      return;
    }
    emailjs
      .sendForm(
        'service_a427lvq',
        'portfolio_template',
        e.target,
        'user_PrnPJQaWtBKsA6uzQKPai'
      )
      .then(
        () => {
          setEmailSuccess(true);
          setName('');
          setEmail('');
          setMessage('');
        },
        () => {
          setEmailSuccess(false);
        }
      );
  };

  let emailSuccessMessage = null;
  let emailMessageColour;

  if (emailSuccess) {
    emailSuccessMessage =
      'Email sent successfully! You should hear back from me soon.';
    emailMessageColour = 'green';
  }

  if (!emailSuccess) {
    emailSuccessMessage = 'Error sending email, please try again!';
    emailMessageColour = 'red';
  }

  if (emailSuccess === null) {
    emailSuccessMessage = null;
  }

  return (
    <form onSubmit={sendEmail}>
      <div className="leftfield">
        <label>Name</label>
        <input
          name="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
        />
      </div>
      <div className="leftfield">
        <label>Email</label>
        <input
          name="email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
      </div>
      <div className="leftfield captcha">
        <ReCAPTCHA
          sitekey="6LeM5voUAAAAAEOd9jSAjiUsqDshsKSWMnfvIMjC"
          onChange={(value) => setCaptcha(value)}
        />
      </div>
      <div className="textarea">
        <label>Your Message</label>
        <textarea
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          required
        />
      </div>
      <input type="submit" value="Submit" />
      <p
        className="validationMessage"
        style={{
          textAlign: 'center',
          color: `${emailMessageColour}`,
        }}
      >
        {emailSuccessMessage}
      </p>
    </form>
  );
};
export default Form;
