import React from 'react';
import Content from './Content';
import projects from '../assets/projects';

const Work = () => (
  <>
    <h1 id="work" className="ProjectsTitle">
      Projects
    </h1>
    <div className="projectContainer">
      <Content proj={projects} />
    </div>
  </>
);

export default Work;
