import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import data from '../assets/data.json';

const { wording } = data.intro;

const Intro = () => (
  <section id="intro" className="intro">
    <ReactTypingEffect
      text={wording}
      speed="75"
      typingDelay="300"
      className="intro-text"
    />
  </section>
);

export default Intro;
