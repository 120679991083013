import React from 'react';
import Form from './Form';

const Contact = () => (
  <section id="contact">
    <h1>Contact</h1>
    <h2>
      Why not drop me a message below? Or directly email me at{' '}
      <a href="mailto:nathan@nathanatherton.com">nathan@nathanatherton.com</a>
    </h2>
    <Form />
  </section>
);

export default Contact;
