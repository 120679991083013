import React, { useState } from 'react';
import CV from '../assets/NathanCV.pdf';

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <header id="top">
      <div className="header-content">
        <a href="#top">Nathan Atherton</a>
        <nav className={`${isExpanded ? 'is-expanded' : ''}`}>
          <a href="#about">About</a>
          <a href="#work">Work</a>
          <a href="#contact">Contact</a>
          <a href={CV} target="_blank" rel="noopener noreferrer">
            CV
          </a>
        </nav>
        <i
          className="fa fa-bars"
          aria-hidden="true"
          onClick={() => setIsExpanded((expanded) => setIsExpanded(!expanded))}
        />
      </div>
    </header>
  );
};

export default Header;
