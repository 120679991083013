import React from 'react';
import data from '../assets/data.json';
import nathan from '../assets/NathanFaceQ.png';

const { title, wording, freelance, freelanceLink } = data.about;

const About = () => (
  <section id="about" className="intro">
    <div className="about-text sb2 box">
      <h1>{title}</h1>
      {wording.map((word) => (
        <p key={word}>{word}</p>
      ))}
      <p>
        {freelance}
        <a href="#contact">{freelanceLink}</a>
      </p>
    </div>
    <img src={nathan} alt="nathan" className="nathanFaceQ" />
  </section>
);

export default About;
