import NpmLogo from './npmLogo.jpg';
import WiganPool from './WiganPool.png';
import WikiApi from './Wiki-api.png';
import PoolMerits from './PoolMerits.png';
import GitTutorial from './GitTutorial.png';
import MemeGenerator from './MemeGenerator.png';
import MatchTheWord from './MatchTheWord.png';
import RandomDefinition from './RandomDefinition.png';
import NewsByNathan from './news-by-nathan.png';
import RRElectrical from './RRElectrical.png';
import WafcleImg from './wafcleImg.png';

const projects = [
  {
    id: 9,
    name: 'WAFCLE',
    desc:
      'A remake of "Wordle" that uses current a previous Wigan Athletic players as the new answers each day',
    img: WafcleImg,
    GitHubLink: 'https://github.com/Nathanavie/wafcle',
    DemoLink: 'https://wafcle.com/',
  },
  {
    id: 1,
    name: 'Custom cypress commands npm package',
    desc:
      'An npm package that allows the user to import a range of custom commands that will shorten the syntax used in automated tests.',
    img: NpmLogo,
    GitHubLink: 'https://github.com/Nathanavie/cypress-custom-commands',
    DemoLink: 'https://www.npmjs.com/package/custom-cypress-commands',
  },
  {
    id: 10,
    name: 'Football Wordles',
    desc:
      'Football Wordles - a repo that is templated to allow new teams to be quickly added when data is obtained.',
    img: WafcleImg,
    GitHubLink: null,
    DemoLink: 'https://footywordles.com/',
  },
  {
    id: 8,
    name: 'Wigan Pool Website',
    desc:
      'A website built for the Wigan pool league, showing stats and allowing teams to upload results and sign up their team members.',
    img: WiganPool,
    GitHubLink: null,
    DemoLink: 'https://www.wiganpool.co.uk/',
  },
  {
    id: 2,
    name: 'Guess The Premier League Footballer',
    desc:
      'A pair project, that is still WIP, utlising the Wikipedia API to create a game guessing the premier league footballer based on appearances, goals and games played.',
    img: WikiApi,
    GitHubLink: 'https://github.com/nouriach/wiki-api-game',
    DemoLink: 'https://guess-the-premier-league-player.netlify.app/',
  },
  {
    id: 3,
    name: 'Wigan Pool League Merits',
    desc:
      'A website that automatically updates as a google sheet is updated, using the sheety.co service to create a RESTFUL API from a Google Sheet.',
    img: PoolMerits,
    GitHubLink: 'https://github.com/Nathanavie/PoolMerits',
    DemoLink: 'https://wiganpoolmerits.netlify.app/',
  },
  {
    id: 4,
    name: 'GIT Animated Tutorial',
    desc:
      'My Dissertation Project that uses CSS Animations to deliver a basic tutorial of how to use GIT well enough to make a repo and push to it.',
    img: GitTutorial,
    GitHubLink: 'https://github.com/Nathanavie/Git-Tutorial',
    DemoLink: 'https://nathanatherton-git.netlify.app/',
  },
  {
    id: 5,
    name: 'Meme Generator Mobile App',
    desc:
      'A React Native project that allows a user to create a two line meme or to retrieve a random meme.',
    img: MemeGenerator,
    GitHubLink: 'https://github.com/Nathanavie/ReactNative',
    DemoLink:
      'https://expo.io/dashboard/nathanavie/builds/769e0995-7774-4ebe-88cb-a0a97b401002',
  },
  {
    id: 6,
    name: 'Random Word Definition Generator Web App',
    desc:
      'A ReactJS project that fetches a random word from a RESTFUL API and then compares this word to another RESTFUL API from Merriam-Webster that will return the relevant definition.',
    img: RandomDefinition,
    GitHubLink: 'https://github.com/Nathanavie/RandomWordProject',
    DemoLink: 'https://randomdefinition.netlify.app/',
  },
  {
    id: 6,
    name: 'Match the word to the definition game',
    desc:
      'A reactJS application that grabs multiple random words and finds the defintion of one and asks the user to select the correct answer.',
    img: MatchTheWord,
    GitHubLink: 'https://github.com/Nathanavie/GuessTheWord',
    DemoLink: 'https://matchtheword.netlify.app/',
  },
  {
    id: 6,
    name: 'News By Nathan',
    desc:
      'A News website created in ReactJS, utilising the NewsAPI to collate news stories based on country or keyword',
    img: NewsByNathan,
    GitHubLink: 'https://github.com/Nathanavie/react-news-articles',
    DemoLink: null,
  },
  {
    id: 7,
    name: 'RR Electricals Website',
    desc:
      'A client website built in HTML and CSS with Javascript elements throughout the page.',
    img: RRElectrical,
    GitHubLink: null,
    DemoLink: 'https://www.rrelectricalservices.co.uk/',
  },
];

export default projects;
